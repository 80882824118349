@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrapper {
	position: relative;
	height: 100%;
	max-width: 100%;
	text-align: center;
	padding: 150px 0 0 0;
	color: white;
}

.logo {
	height: auto;
	width: 80px;

	margin-bottom: 25px;

	[data-theme='glo'] & {
		width: 80px;
	}

	[data-theme='glo'] & {
		width: 105px;
	}

	[data-locale='uae_gtr'],[data-theme='velo'] & {
		margin-bottom: 0;
		width: 140px;

	}

	[data-locale='uae_gtr'],[data-theme='vuse'] & {
		margin-bottom: 0;
		width: 140px;

	}
}

.title {
	font-size: 56px;
	text-align: center;

	[data-theme='velo'] & {
		font-family: $font-family-velo;
		font-weight: $velo-bold;
	}

	[data-theme='vuse'] & {
		font-family: $font-family-trim;
		font-weight: $trim-black;
		font-size: 60px;
	}

	[data-theme='glo'] & {
		font-family: $font-family-mont;
		font-weight: $mont-heavy;
		height: 100px;
	}

	
	[data-locale='uae_gtr'],[data-theme='glo'] & {
		font-weight: 700;
	}	

	[data-locale='uae_gtr'],[data-theme='velo'] & {
		color: #FDF351;
		font-family: $font-family-velo-wangle;
		font-size: 52px;	
	}	

}
.strapline {
	font-family: $font-family-gotham;
	font-weight: $gotham-book;
	font-size: 24px;
	line-height: 1.5;

	margin-bottom: 50px;
}

.countriesWrapper {
	display: flex;
	flex-flow: row nowrap;
	margin: 0 300px;
	text-align: left;

	[data-theme='vuse'] & {
		margin: 0 145px 15px;
	}


	[data-locale='uae_gtr'][data-theme='vuse'] & {
		margin: 0 90px 15px;
	}

	[data-locale='uae_gtr'][data-theme='velo'] & {
		margin: 0 260px;
	}

	[data-locale='uae_gtr'][data-theme='vuse'] & {
		justify-content: space-between;

	}
}

.continent {
	flex: 1 1 350px;
	min-width: 350px;

	[data-theme='vuse'] & {
		flex: 1 1 320px;
		min-width: 320px;
	}

	[data-locale='uae_gtr'][data-theme='glo'] & {
	flex: 1 1 225px;
	min-width: 225px;
	}

	[data-locale='uae_gtr'][data-theme='vuse'] & {
		flex: 1 1 225px;
	min-width: 225px;
	}

	[data-locale='uae_gtr'][data-theme='velo'] & {
		flex: 1 1 200px;
		min-width: 200px;
	}

	[data-locale='uae_gtr'][data-theme='vuse'] & {
		min-width: 0 !important;
		flex: none;

	}
}

.continentLabel {
	font-family: $font-family-gotham;
	font-weight: $gotham-bold;
	font-size: 26px;
	text-transform: uppercase;

	margin-bottom: 10px;
	display: block;
}

.countries {
	display: flex;
	height: 335px;

	flex-direction: column;
	justify-content: flex-start;
	flex-wrap: wrap;

	text-align: left;

	[data-theme='glo'] & {
		height: 270px;
	}

	[data-locale='uae_gtr'][data-theme='glo'] & {
		height: 360px;
	}

	[data-locale='uae_gtr'][data-theme='vuse'] & {
		height: 360px;
	}



	[data-locale='uae_gtr'][data-theme='velo'] & {
		height: 360px;
	}
}

.country {
	display: flex;
	flex-flow: row nowrap;
	white-space: nowrap;
	align-items: center;
	margin-bottom: 10px;

	[data-locale='uae_gtr'][data-theme='vuse'] & {
		min-width: 260px;
	}
}

.flag {
	width: 28px;
	height: 28px;
	margin-right: 14px;
	border-radius: 100%;

	overflow: hidden;

	border: 1.5px solid white;
	background-position: center center;
	background-size: 130%;
}

.countryLabel {
	font-family: $font-family-gotham;
	font-weight: $gotham-book;
	font-size: 22px;

	[data-locale='uae_gtr'][data-theme='vuse'] & {
		font-size: 18px;
	}
}
