@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.productsInfo {
	position: relative;
	flex: 0 0 750px;

	padding: 125px 20px rescale(18.6px) 125px;

	text-align: left;
	white-space: pre-line;

	[data-theme='velo'] & {
		padding: 65px 20px rescale(18.6px) 125px;
	}


	[data-theme='velo'] & {
		padding: 65px 20px rescale(18.6px) 125px;
	}

	&.noVideos {
		padding: rescale(90px) 0px rescale(18.6px) rescale(90px);
	}

	.logo {
		[data-theme='glo'] & {
			width: auto;
			height: 97px;
		}

		[data-theme='vuse'] & {
			width: auto;
			height: 125px;
		}

		[data-locale='uae_gtr'][data-theme='vuse'] & {
			width: auto;
			height: 65px;
		}

		[data-theme='velo'] & {
			width: auto;
			height: 145px;
		}
		
	}

	.videoText {
		font-family: $font-family-gotham;
		font-weight: $gotham-book;
		color: white;
		font-size: rescale(12px);
		letter-spacing: -0.022em;

		margin-bottom: 7.3px;

		&.velo {
			color: black;
		}

		.divider {
			margin: 0 12px;
		}

		.strong {
			font-weight: $gotham-black;
		}
	}

	.video {
		width: 95%;
		position: relative;

		img {
			width: auto;
			height: 145px;
		}

		.playIcon {
			position: absolute;
			top: 36%;
			height: 41px;
			left: 41%;
		}

		.thumbnailText {
			display: block;
			text-align: center;

			font-family: $font-family-gotham;
			font-weight: $gotham-medium;
			font-size: 16px;
			letter-spacing: 0;
		}
	}

	h3 {
		&.velo {
			font-weight: $velo-bold;
			font-size: 28px;
			letter-spacing: 0.01;
			line-height: 1.142em;

			text-transform: uppercase;
			color: white;
			font-family: $font-family-velo-wangle;
		}
	}

	h2 {
		font-size: rescale(30px);
		letter-spacing: -0.01em;
		line-height: 1.23;

		&.vuse {
			font-family: $font-family-trim;
			font-weight: $trim-black;
			font-size: 56px;

			margin: 38px 0 24px;

			margin-top: 94px;
			line-height: 1;
			letter-spacing: 0.01em;
			color: white;

			text-align: left;

			[data-locale='uae_gtr'] &, [data-locale='de_gtr'] &  {
			margin: 38px 0 24px;
			margin-top: 64px;

			}
		}

		&.glo {
			font-family: $font-family-mont;
			font-weight: $mont-heavy;
			font-size: 56px;
			line-height: 1;
			letter-spacing: 0.01em;
			color: white;

			margin: 28px 0 40px;

			text-align: left;

			[data-locale='uae_gtr'] & {
				font-weight: 700;
				letter-spacing: 0;
			}
		}
		&.velo {
			font-family: $font-family-velo;
			font-weight: $velo-bold;
			font-size: 48px;
			letter-spacing: 0.01;
			line-height: 1.142em;

			text-transform: uppercase;
			color: #192a53;

			margin: 32px 0 20px 0;

			[data-locale='uae_gtr'] & {
			color: #FDF351;
			font-family: $font-family-velo-wangle;
			font-size: 44px;
			}
		}
	}

	p {
		margin: 0;
		font-family: $font-family-gotham;
		font-weight: $gotham-book;
		font-size: 25px;

		line-height: 1.44;

		color: white;
		
		min-height: 200px;
		&.vuse {
			margin-bottom: 23px;
		}

		&.glo {
			margin-bottom: 90px;
		[data-locale='uae_gtr'] & {	
			letter-spacing: 0;
		}
		}

		&.velo {
			color: black;
			margin-bottom: 45px;

			[data-locale='uae_gtr'] & {
				color: white;
			}
		}
	}

	.videosContainer {
		display: flex;
		flex-flow: row nowrap;

		.videoContainer {
			display: flex;
			flex-direction: column;
		}


		[data-locale='uae_gtr'],[data-theme='velo'] & {
			color: white;
		}
	}
}

.ctaWrapper {
	display: flex;


	[data-locale='uae_gtr'][data-theme='velo'] & {
		color: white;
	}

	[data-locale='uae_gtr'],[data-theme='glo'] & {	
		margin-top: -30px;
	}
}
