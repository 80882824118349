@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.contents {
	position: relative;
	width: 100%;

	z-index: 5;
	margin: 155px auto 0;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	// padding-top: rescale(100px);
}

.disclaimer {
	position: absolute;
	bottom: 10px;

	text-align: center;
	font-size: 15px;
	font-family: $font-family-gotham;
	font-weight: $gotham-book;

	line-height: 24px;
	letter-spacing: 0;
	padding: 0 70px;

	color: white;


	[data-locale='uae_gtr'] & {
		font-size: 10px;
		line-height: 15px;
	}
	
}