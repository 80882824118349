@import '../../../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/functions';

.colours {
	display: flex;
	flex-flow: row nowrap;
	margin-bottom: 20px;

	.colour {
		width: rescale(28px);
		height: rescale(28px);

		border: 1px solid white;
		border-radius: 50%;

		margin-right: 20px;

		[data-locale='de'][data-theme='glo'] & {
			width: 42px;
			height: 42px;
		}

		[data-page='accessories'] & {
			width: 45px;
			height: 45px;

			border: 2px solid white;
		}

		[data-locale='za'] &, [data-locale='de_gtr'] &, [data-locale='uae_gtr'] &  {
			width: 30px;
			height: 30px;
			margin-right: 18px;

			&:nth-child(even) {
				&:last-child {
					margin-right: 0px;
				}
			}

		}
	}
}

.colourText {
	[data-locale='de'][data-theme='glo'] & {
		display: block;
		font-size: 20px;
		font-weight: $gotham-book;

		line-height: 1.5;
		letter-spacing: 0.01em;
		margin: 28px 0 44px;
	}
}

.disclaimer {
	[data-locale='de'][data-theme='glo'] & {
		display: block;
		font-size: 16px;
		font-weight: $gotham-book;
		color: #b5b5b5;

		line-height: 1.5;
		letter-spacing: 0.01em;
	}
}

.colourTitle {
	display: block;

	font-size: rescale(10px);
	font-weight: $gotham-light;

	margin-bottom: rescale(6px);
	line-height: 3.142;

	text-transform: uppercase;

	[data-locale='de'][data-theme='glo'] & {
		margin-top: 37px;
	}

	[data-page='accessories'] & {
		margin-top: 47px;
	}
}

.coloursContainer {
	&.center {
		margin-top: auto;
		margin-bottom: 140px;
		z-index: 2;
	}
}

[data-locale='us'] {
	.colourTitle {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 0;
	}
	.velo .colourTitle {
		font-family: $font-family-velo;
	}
}

[data-locale='uk'],
[data-locale='za']
[data-locale='uae_gtr'] {
	.colour {
		border: 2px solid rgba(255, 255, 255, 0.3);

		width: 44px;
		height: 44px;

		margin-right: 25px;
	}
	.colourTitle {
		letter-spacing: 0.02em;
		font-weight: $gotham-medium;
	}
}
