@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.nav {
	position: fixed;
	display: flex;
	align-items: center;

	width: 100%;

	top: 0;
	left: 0;
	z-index: 10;

	background-color: #f5f5f6;
	box-shadow: 0px 2px 12px rgba(#5e5e5e, 0.5);
}

.offset {
	width: 100%;
	height: 76px;
	position: absolute;
}

.links {
	display: flex;

	margin-left: auto !important;

	overflow: hidden;
}

.link {
	padding: 25px 35px;
	display: block;
	text-align: center;

	color: #5e5e5e;

	font-family: $font-family-gotham;
	text-transform: uppercase;

	background-color: transparent;
	appearance: none;
	border: none;

	&.active {
		color: #fff;
		background-color: #5e5e5e;
	}
}

.linkImg {
	padding: 0 40px;
	display: block;

	appearance: none;
	border: none;

	height: 65px;
	margin: 5px 0 0;
}

.back {
	color: #818181;

	font-family: $font-family-gotham;
	font-weight: $gotham-medium;
	letter-spacing: 0.02em;
	font-size: 17px;
	text-transform: uppercase;

	margin-left: 8px;

	display: flex;
	align-items: center;

	padding: 0;
	align-self: stretch;
	border: none;
	background-color: transparent;

	cursor: pointer;
}

.backWrapper {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.logo {
	width: 52px;
	height: 52px;
	margin: 0px 20px 0px 10px;
}

.title {
	display: block;
	font-size: 24px;
	font-weight: $gotham-bold;
	line-height: 1.07;
	letter-spacing: 0;
}

.item {
	position: relative;

	&+& {
		.link::before {
			content: '';
			position: absolute;
			height: 70%;
			top: 15%;
			left: -1px;

			width: 1px;
			background-color: rgba(#5e5e5e, 0.4);
		}
	}
}