@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrapper {
	position: relative;
	height: 100%;

	text-align: center;
	padding: 150px 0 0 0;
	color: black;

	[data-locale='de'] & [data-locale='de_gtr'] & {
		color: #fff;
	}

	[data-theme='vuse'] & {
		color: white;
	}

	&.qrCodes {
		padding: 210px 0 0 0;
	}
}

.title {
	font-size: 56px;
	text-align: center;

	[data-theme='velo'] & {
		color: #192a53;
		font-family: $font-family-velo;
		font-weight: $velo-bold;
	}

	[data-locale='uae_gtr'][data-theme='velo'] & {
		color: #FDF351;
		font-family: $font-family-velo-wangle;
		font-weight: $velo-bold;
		font-size: 52px;
	}

	[data-theme='vuse'] & {
		font-family: $font-family-trim;
		font-weight: $trim-black;
		font-size: 60px;
	}

	[data-theme='glo'] & {
		font-family: $font-family-mont;
		font-weight: $mont-heavy;
	}

	.qrCodes & {
		[data-theme='vuse'] & {
			font-family: $font-family-gotham;
			font-weight: $gotham-black;
			font-size: 56px;
		}
	}
}

.strapline {
	font-family: $font-family-gotham;
	font-weight: $gotham-book;
	font-size: 24px;
	line-height: 1.5;

	margin-top: 5px;

	[data-locale='uae_gtr'][data-theme='velo'] & {
		color: #fff;
	}

	.qrCodes & {
		font-size: 32px;
		letter-spacing: 0;
		line-height: 1.25;
		margin-top: 10px;
	}
}

[data-page="pick"] {
	.title {
		[data-theme='velo'] & {
			color: #fff;
		}
	}

	.strapline {
		[data-theme='velo'] & {
			color: #fff;
		}
	}

	.back-button_backButton{
		color: white !important;
		background: #69abd2 !important;
	}
}