@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.contents {
	position: relative;
	width: 90%;
	min-height: 100%;
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: center;
}

.block {
	position: relative;

	font-family: $font-family-gotham;
	color: white;
	text-align: center;

	white-space: pre-line;

	flex: 0 0 50%;
}

.blockInner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 10vh 0;

	margin: 60px auto 0;
}

.blockStart {
	margin-top: 25px;
}

.blockEnd {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title {
	text-align: center;
	font-weight: $gotham-black;
	font-size: 40px;
	letter-spacing: 0;
	line-height: 1;

	text-transform: uppercase;
}

.detail {
	text-align: center;
	font-weight: 400;
	font-size: 24px;
	letter-spacing: 0;
	line-height: 1.3333;
	min-height: 100px;

	padding: 0px 30px;
	margin-top: 40px;
	margin-bottom: 20px;

	white-space: pre-wrap;
}

.imgButton {
	width: 70px;
}

.divider {
	@extend %divider;

	width: 120px;
	background-color: white;
	margin: 25px auto 30px;
}

.logoImg {
	width: 100px;
	height: auto;
}
