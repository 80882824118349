@import '../../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../../node_modules/bat-components/dist/styles/includes/functions';

.block {
	position: relative;

	font-family: $font-family-gotham;
	color: white;
	text-align: center;

	display: flex;
	flex-direction: column;
	height: 100%;

	white-space: pre-line;

	flex: calc(90% / var(--cols)) 0;
	padding: 0 calc((1 / var(--cols) * 320px) / 2) 0;

	&:not(:first-child) {
		&::before {
			content: '';

			border: 0.5px solid white;

			position: absolute;
			height: 98%;
			left: 0;
			top: 2%;
		}
	}
}

.title {
	text-align: center;
	font-weight: $gotham-black;
	font-size: 42px;
	letter-spacing: 0;
	line-height: 0.95;

	text-transform: uppercase;

	margin-bottom: 15px;


	[data-locale='uae_gtr'] & {
		font-size: 36px;
	}
	
}

.detail {
	text-align: center;
	font-weight: 400;
	font-size: 24px;
	letter-spacing: -0.02em;
	line-height: 1.3333;

	margin-bottom: 30px;

	white-space: pre-wrap;
}

.copyWrapper {
	display: flex;
	flex-direction: column;
}

.bulletPoints {
	text-align: left;

	font-family: $font-family-gotham;
	font-weight: $gotham-book;
	font-size: 24px;

	line-height: 1.307;
	letter-spacing: -0.022em;

	min-height: rescale(220px);


	[data-locale='uae_gtr'] & {
		min-height: rescale(200px);
	}
	

	.wrapper {
		display: flex;
		flex-flow: row nowrap;

		border-top: 1px solid rgba(white, 0.4);
		padding: rescale(10px) 0px rescale(12px);

		&:last-child() {
			border-bottom: 1px solid rgba(white, 0.4);
		}
	}

	.icon {
		flex: 0 0 auto;
		width: 36px;
		height: auto;

		margin-right: 27px;

		position: relative;
	}

	&.smush {
		font-size: rescale(16px);
	}
}

.ctaWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	button,
	a {
		font-size: rescale(13px);
	}
}

.divider {
	@extend %divider;
}

.logoImg {
	width: auto;
	height: 135px;

	margin: 20px 0;
}
