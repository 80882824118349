@import '../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../node_modules/bat-components/dist/styles/includes/functions';

.scrollWrapper {
	position: relative;
	grid-area: content;

	transition: transform 0.6s ease-in-out;
	transition-delay: 0.4s;
	padding-top: 0;

	z-index: 5;
}

.black {
	position: relative;
	width: 100vw;
	height: 100vh;

	background-color: black;
	z-index: 1000;
}
