@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.itemsWrapper {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;

	max-width: 67%;
	margin: 0 auto;

	&.scan {
		justify-content: center;
	}
}

.item {
	margin-top: 105px;
	flex: 0 0 276px;
	display: flex;
	flex-direction: column;

	.scan & {
		margin-top: 40px;
	}
}

.qrCode {
	//Height and width should be done directly in the component
	flex: 0 0 auto;
	margin: 0 auto;
}

.logoQr {
	flex: 0 0 auto;
	height: 150px;
	width: 150px;
	margin: 40px auto 0;
}

.introWrapper {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;

	position: relative;
	width: 100%;
	height: 100%;

	text-align: center;
	font-family: $font-family-gotham;

	white-space: pre-line;

	.title {
		display: block;
		margin-top: 30px;
		margin-bottom: 10px;

		font-weight: $gotham-black;
		font-size: 56px;
	}

	.strapline {
		font-weight: $gotham-book;
		font-size: 32px;
		line-height: 1.25;
		letter-spacing: 0;
		margin-bottom: 60px;
	}

	.btnContainer {
		button {
			min-width: 337px;
			padding: 20px 20px;
		}
	}
}
