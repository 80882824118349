@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-top: 15vh;

	position: relative;
	z-index: 5;

	color: white;

	white-space: pre-line;
	text-align: center;

	.title {
		font-family: $font-family-gotham;
		font-weight: $gotham-black;

		font-size: 40px;
		letter-spacing: 0;
		line-height: 1;

		text-transform: uppercase;
	}

	.columns {
		display: flex;
		flex: 1 1 auto;

		flex-flow: row nowrap;
		justify-content: center;
		align-items: flex-end;

		height: 100%;
		padding-bottom: 80.6px;

		.column {
			display: flex;
			flex-direction: column;
			flex: 0 0;
			flex-basis: rescale(310px);

			align-items: center;

			img {
				height: auto;
				width: 100%;

				object-fit: contain;
				object-position: bottom;

				max-height: 470px;
				margin: 0;

				margin-top: 46px;
			}

			.name {
				display: block;
				font-family: $font-family-gotham;
				font-weight: $gotham-book;

				margin-bottom: 30px;
				padding-top: 30px;

				font-size: 24px;
				letter-spacing: 0;
				line-height: 1.08;
				color: white;
			}

			button,
			a {
				padding: 15px 30px;
			}
		}
	}
}
