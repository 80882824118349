@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.backButton.customisedToProduct {
	position: absolute;
	bottom: 28px;
	padding: 20px 75px;
	transform: translateX(-55%);
	border: none !important;
	line-height: 1.1;

	[data-theme='vuse'] & {
		color: white !important;
		background: linear-gradient(90deg, rgba(241, 176, 44, 1) 0%, rgba(235, 92, 33, 1) 100%);
	}

	[data-theme='velo'] & {
		color: white !important;
		background: linear-gradient(90deg, #00acd5 0%, #006bb8 100%);
	}
	[data-theme='glo'] & {
		background: white;
	}

	[data-locale='uae_gtr'][data-theme='velo'] & {
		color: #192a53 !important;
		background: #fff;
		}
}

[data-page='pick'] {
	.backButton.customisedToProduct {
		background: #fff !important;
		color: #00acd5 !important;
	}
}
