@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.flavoursWrapper {
	position: relative;
	text-align: center;

	height: 100%;
	width: 100%;
	max-width: 100vw;
}
