@import '../../../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/functions';

.adWrapper {
	display: flex;
	flex-direction: column;

	margin-left: 60px;
}

.image {
	height: 125px;
	margin: auto;
}
