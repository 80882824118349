@import '../../../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/functions';


.product {
	position: relative;
	flex: 0 0 auto;
	align-items: center;

	height: 100%;
	width: 380px;

	[data-locale='de_gtr'][data-theme='vuse'] &, [data-locale='uae_gtr'][data-theme='vuse'] & {
		margin-left: -30px;
	}


	[data-layout='reduced'] & {
		width: 480px;
	}

	.productImage {
		flex: 0 0 auto;
		position: absolute;
		height: 670px;
		width: 380px;

		[data-layout='reduced'] & {
			width: 480px;
			}

			[data-locale='uae_grt'][data-theme='vuse'] & {
				margin-right: -0 !important;
			}

		object-fit: contain;
		object-position: center;
	}
}


.textUnder {
	margin-top: auto;
	margin-bottom: 140px;

	font-family: $font-family-velo;
	font-weight: $velo-regular;
	font-size: 24px;
	letter-spacing: -0.01em;
	line-height: 1.16;

	text-align: center;
}
