@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 75px;

	display: flex;
	flex-flow: row nowrap;

	z-index: 5;
}
