@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.contents {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 100%;

	display: flex;
	justify-content: center;
}
