@import '../../../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/functions';

.text {
	position: absolute;
	bottom: 25px;
	width: 580px;

	font-family: $font-family-gotham;
	font-weight: $gotham-light;
	font-size: 12px;

	opacity: 0;
	animation-name: appear;
	animation-delay: 0.6s;
	animation-duration: 0.7s;
	animation-fill-mode: forwards;

	line-height: 1.167;
	letter-spacing: -0.01em;
	color: black;

	[data-theme='glo'] & {
		color: white;
		bottom: 50px;
	}


	[data-locale='uae_gtr'][data-theme='velo'] & {
		color: white;
	}
}

@keyframes appear {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
