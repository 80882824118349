@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.iconsContainer {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;

	max-width: 80%;
	margin: 0 auto;
}

.iconWrapper {
	margin-top: 100px;
	flex: 0 0 438px;
	display: flex;
	flex-direction: column;

	[data-theme='vuse'] & {
		flex: 0 0 474px;
	}
}

.icon {
	height: 210px;
	width: auto;

	margin-bottom: 25px;

	[data-theme='vuse'] & {
		margin-bottom: 35px;
		height: 170px;
	}

	[data-theme='velo'] & {
		height: 70px;
		margin-bottom: 50px;
	}
}

.title-with-strapline_strapline {
	[data-theme='velo'] & {
		color: #fff !important;
	}
}

.title {
	font-size: 36px;
	letter-spacing: 0.02em;
	margin-bottom: 5px;
	line-height: 1;

	[data-theme='velo'] & {
		color: #fff !important;
		font-family: $font-family-velo;
		font-weight: $velo-bold;
	}

	[data-theme='vuse'] & {
		font-family: $font-family-gotham;
		font-weight: $gotham-medium;
		font-size: 31px;
	}

	[data-theme='glo'] & {
		font-family: $font-family-mont;
		font-weight: $mont-heavy;
	}
}

.copy {
	font-family: $font-family-gotham;
	font-weight: $gotham-book;
	font-size: 20px;
	line-height: 1.5;
	letter-spacing: 0;

	margin-top: 5px;

	[data-theme='velo'] & {
		color: #fff;
	}
}

[data-theme=velo] .title-with-strapline_title__2E2Y- {
    color: #fff;
    font-family: "Velo", sans-serif;
    font-weight: 600;
}